export enum PubSubState {
	POLLING_ONLY,
	PUBSUB_ONLY,
	ACTIVE,
	TERMINATED,
}

export const INACTIVE_TAB_WAIT_INTERVAL = 2000;

export enum PubSubFlagType {
	COMMENT,
	EDIT,
}

export enum PageMode {
	EDITOR,
	LIVE,
	RENDERER,
}
