import React, { useEffect } from 'react';
import { Query } from 'react-apollo';

import { ContentViewedEventQuery } from './ContentViewedEventQuery.graphql';

type ContentViewedEventProps = {
	contentId: string;
};

let lastContentId: string;

export const ContentViewedEvent = ({ contentId }: ContentViewedEventProps) => {
	useEffect(() => {
		lastContentId = contentId;
	}, [contentId]);

	return (
		<Query
			query={ContentViewedEventQuery}
			variables={{ contentId }}
			fetchPolicy="network-only"
			skip={lastContentId === contentId}
		>
			{() => null}
		</Query>
	);
};
