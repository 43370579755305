import type React from 'react';

export type AnchorLinkClickOptions = {
	anchorElement: HTMLElement;
	hash: string;
	hasTableParent: boolean;
};

/**
 * This method tries to figure out whether the given `event` is a click event on an anchor link
 * that will take the user to an anchor. The anchors are looked up inside the given `container`.
 *
 * This method will return `null` whenever it is not fully certain that a browser anchor navigation will follow the given `event`.
 * For example, if a meta key was pressed along with the click, or a right-click event, or a click on external link -- all of these
 * will return `null`.
 *
 * Consumers should note, that the given event might have been prevented, and should take action accordingly. So, if one is looking to
 * change the behavior of *this* event, `event.defaultPrevented` should be checked in order to ensure there is no conflicting behavior.
 * If, on the other hand, some *other* behavior needs to be changed in relation to the given `event` occurrence, `event.defaultPrevented`
 * check can be omitted.
 *
 * @param event - the event that you want to check. Generally, this should be a parameter received by `node.addEventListener("click")`
 * @param container - the lookup for target anchor element will be limited to just this container.
 */
export function getHashLinkClickOptions(
	event: React.MouseEvent | MouseEvent | null | undefined,
	container: HTMLElement | Document = document,
): AnchorLinkClickOptions | null {
	if (!event) {
		// incorrect input
		return null;
	}

	if (event.button !== 0) {
		// this wasn't a left-click
		return null;
	}

	if (event.type !== 'click') {
		// we only handle clicks. For anchor elements, performing an "enter" on a focused element is equivalent to a left-click
		return null;
	}

	if (event.metaKey || event.altKey || event.ctrlKey || event.shiftKey) {
		// ignore clicks with modifier keys
		return null;
	}

	// This might be the child of an anchor (such as the span inside of a smart card),
	// so check for a parent anchor.
	// The check for e.target.closest is for IE.
	const { target } = event;
	// @ts-ignore
	const closest = target ? target['closest'] : null;
	const parentAnchor = closest ? closest.call(target, 'A') : null;
	const eventTarget = parentAnchor || target;

	if (!(eventTarget instanceof HTMLAnchorElement)) {
		// this method doesn't care about non-anchor elements
		return null;
	}

	if (eventTarget.target && eventTarget.target !== '_self') {
		// we should let browser handle targets like "_blank", "_top", or such
		return null;
	}

	if (!eventTarget.hash) {
		// the anchor element does not have a hash defined
		return null;
	}

	const ownerDocument = eventTarget.ownerDocument;
	if (!ownerDocument || ownerDocument !== document) {
		return null;
	}

	// we are not checking for search strings here explicitly -- even if the search string in
	// link and in the document are different, the assumption is that this is still the same document
	const linkOwnerPath = ownerDocument.location.origin + ownerDocument.location.pathname;
	const linkHrefPath = eventTarget.origin + eventTarget.pathname;
	const isSameDocumentReference = linkOwnerPath === linkHrefPath;

	if (!isSameDocumentReference) {
		// this is a link with a hash, but it doesn't point to the same document it is on
		return null;
	}

	let decodedHash = '';
	try {
		// try to decode the hash
		decodedHash = decodeURIComponent(eventTarget.hash.substring(1));
	} catch (e) {
		// if hash is not encoded, it will throw a URI Malformed error,
		// which we will then return the original hash
		decodedHash = eventTarget.hash.substring(1);
	}

	// `event.target.hash` will be in the form of `#hash-path`.
	// `document.querySelector` might choke on input like "#id>.", so we use `document.getElementById` here
	const anchorElement =
		document.getElementById(decodedHash) ||
		document.querySelector(`[name="${CSS.escape(decodedHash)}"]`);

	// check if element is nested inside table, if so we need to account for
	// sticky table header overlapping the element
	const hasTableParent = !!anchorElement?.closest('.pm-table-container td');

	if (anchorElement == null) {
		// this is a link with a hash to the same document it is on, but the hash element does not exist in the DOM
		return null;
	}

	if (!container.contains(anchorElement)) {
		// this is outside of the boundaries that we care about
		return null;
	}

	return {
		anchorElement,
		hash: decodedHash,
		hasTableParent,
	};
}
