import { useEffect, useRef } from 'react';

import { useSessionData } from '@confluence/session-data';
import type { PageLoad, PageSegmentLoad } from '@confluence/browser-metrics';
import { ExperienceActionMeasures } from '@confluence/action-measures';
import {
	VIEW_EDIT_BUTTON_CLICKED_ACTION,
	createPageLoadMetric,
	isInitial,
	VIEW_PAGE_LOAD_KEY,
	markBrowserMetricEnd,
	BYLINE_SEGMENT_KEY,
	APP_NAVIGATION_SEGMENT_KEY,
	CONTENT_TITLE_SEGMENT_KEY,
	INLINE_COMMENTS_HIGHLIGHTS_SEGMENT_KEY,
	SPACE_NAVIGATION_SEGMENT_KEY,
	PAGE_TREE_SEGMENT_KEY,
	FAVOURITE_BUTTON_SEGMENT_KEY,
	RESTRICTIONS_BUTTON_SEGMENT_KEY,
	WATCH_BUTTON_SEGMENT_KEY,
	VIEW_PAGE_CONTAINER_SEGMENT_KEY,
	getWaterfallTimings,
	createPageSegmentLoadMetric,
	resetViewTransitionSource,
} from '@confluence/browser-metrics';
import { resumeLowPriorityEvents } from '@confluence/analytics-event-delay';
import { APP_NAVIGATION_METRIC } from '@confluence/browser-metrics/entry-points/app-navigation.metric';
import {
	BYLINE_METRIC,
	CONTENT_TITLE_METRIC,
} from '@confluence/content-header/entry-points/pageSegmentLoadMetrics';
import { FAVOURITE_BUTTON_METRIC } from '@confluence/favourite-button/entry-points/pageSegmentLoadMetrics';
import { INLINE_COMMENTS_HIGHLIGHTS_METRIC } from '@confluence/inline-comments-common/entry-points/pageSegmentLoadMetrics';
import { PAGE_TREE_METRIC } from '@confluence/page-tree/entry-points/pageSegmentLoadMetrics';
import { RESTRICTIONS_BUTTON_METRIC } from '@confluence/restrictions/entry-points/pageSegmentLoadMetrics';
import { SPACE_NAVIGATION_METRIC } from '@confluence/side-navigation/entry-points/pageSegmentLoadMetrics';
import { markLegacyFY23TTI } from '@confluence/view-content-perf-metrics/entry-points/legacyFY23PerformanceMetrics';
import { CONTENT_RENDERER_METRIC } from '@confluence/view-content-perf-metrics/entry-points/pageSegmentLoadMetrics';
import { WATCH_BUTTON_METRIC } from '@confluence/watch-dialog/entry-points/pageSegmentLoadMetrics';
import {
	debuggerEnabled,
	cacheExpectedViewPageSegments,
} from '@confluence/analytics-debugger/entry-points/AnalyticsDebugger';

/*
There isn't an explicit type for the page load segments passed to `expectedPageLoadSegments`.
However, we can leverage TypeScript's TypeOf Operator (https://www.typescriptlang.org/docs/handbook/2/typeof-types.html)
to create a type that represents page load segments.
*/

type PageLoadSegments = typeof CONTENT_RENDERER_METRIC;
export interface CustomViewPageLoadConfig {
	pageLoadKey?: PageLoad;
	fmpUntilStopTimeMetrics?: PageSegmentLoad[];
	expectedPageLoadSegments?: PageLoadSegments[];
}

const baseConfig = {
	slo: {
		// based on production p90
		fmp: {
			initial: { threshold: 14300 },
			transition: { threshold: 3200 },
		},
		tti: {
			initial: { threshold: 14300 },
			transition: { threshold: 3200 },
		},
	},
	histogram: {
		// based on pre-production pollinator and prod p90
		initial: {
			fmp: '5000_6000_7000_8000_10000_12000_14000',
			tti: '5000_6000_7000_8000_10000_12000_14000',
		},
		transition: {
			fmp: '2000_2500_3000_3500_4000_4500_5000',
			tti: '2000_2500_3000_3500_4000_4500_5000',
		},
	},
	timings: [
		// breakdowns in order of occurrence
		{ key: 'html', endMark: 'html' },
		{ key: 'fy21-legacy-fmp', endMark: 'fy21-legacy-fmp' },
		{ key: 'appLoad', startMark: 'html', endMark: 'app' },
		{
			key: 'appRootRender',
			startMark: 'app',
			endMark: 'root-component-render',
		},
		{
			key: 'routerMatched',
			startMark: 'root-component-render',
			endMark: 'route-manager-initialized',
		},
		{
			key: 'onBeforeAkRenderer',
			endMark: 'onBeforeAkRenderer',
		},
		{ key: 'fy21-legacy-tti', endMark: 'fy21-legacy-tti' },
		{ key: 'fy23-legacy-tti', endMark: 'fy23-legacy-tti' },
	],
};

const NOOP = () => {};

export const getExpectedPageLoadSegments = ({
	isLite,
	isAnonymous,
	isInitialLoad,
}: {
	isLite?: boolean;
	isAnonymous: boolean;
	isInitialLoad: boolean;
}) => {
	if (isLite) {
		return [BYLINE_METRIC, CONTENT_TITLE_METRIC, CONTENT_RENDERER_METRIC];
	}

	const segments = [
		// New metric adding to this list need also be considered adding to fmpAsUntilStopTimeMetrics
		// See comments in fmpAsUntilStopTimeMetrics for more information
		BYLINE_METRIC,
		CONTENT_TITLE_METRIC,
		CONTENT_RENDERER_METRIC,
		INLINE_COMMENTS_HIGHLIGHTS_METRIC,
		VIEW_PAGE_CONTAINER_METRIC,
	];

	if (isInitialLoad) {
		segments.push(APP_NAVIGATION_METRIC, SPACE_NAVIGATION_METRIC);
		segments.push(PAGE_TREE_METRIC);
	}

	if (!isAnonymous) {
		// Note: the edit button metric is not currently tracked because there is no
		// guarantee that segment will fire even for authenticated users
		segments.push(FAVOURITE_BUTTON_METRIC, RESTRICTIONS_BUTTON_METRIC, WATCH_BUTTON_METRIC);
	}

	return segments;
};

/**
 * fmpAsUntilStopTimeMetrics
 *
 * What is minimally interactive?
 * Minimally interactive during the SSR phase at it's core, is determined if the component/element is clickable,
 * can register the action, and display a visual response that the action has been received.
 * The SSR phase can be stalled by appending ?NO_SPA=1 at the end of a CONNIE url.
 *
 * I.E. If you click on an inline comment/highlight, the click is registered, and displays a loading sidebar component.
 *
 * If a required segment is SSR'd and determined to be minimally interactive at FMP, ADD TO THIS LIST
 * If a required segment is SSR'd but determined to NOT be minimally interactive at FMP, DO NOT ADD TO THIS LIST
 * If a required segment is yet to be SSR'd, this config will not update TTI for that segment, by default is OFF.
 *
 * CONTEXT
 * the returned segments are considered to be minimally interactive during SSR
 * hence we want to use FMP (ssr-ttr mark)
 *
 * PageSegment TTI is measured: useFmpAsUntilStopTime ? FMP : Eventual load time
 * Content-renderer TTI: macros are SSRed ? FMP : Eventual Load Time
 *
 * View Page TTI is measured: Math.max(Non SSR PageSegments TTI, Content-renderer TTI)
 *
 */
const fmpAsUntilStopTimeMetrics = ({ isLite }): string[] => {
	if (isLite) {
		return [BYLINE_SEGMENT_KEY, CONTENT_TITLE_SEGMENT_KEY];
	}

	return [
		BYLINE_SEGMENT_KEY,
		CONTENT_TITLE_SEGMENT_KEY,
		INLINE_COMMENTS_HIGHLIGHTS_SEGMENT_KEY,
		APP_NAVIGATION_SEGMENT_KEY,
		SPACE_NAVIGATION_SEGMENT_KEY,
		PAGE_TREE_SEGMENT_KEY,
		FAVOURITE_BUTTON_SEGMENT_KEY,
		RESTRICTIONS_BUTTON_SEGMENT_KEY,
		WATCH_BUTTON_SEGMENT_KEY,
		VIEW_PAGE_CONTAINER_SEGMENT_KEY,
	];
};

// This component assumes startBrowserMetricsPageLoad() has already been called from
// packages/confluence-frontend-server/src/components/Root/App.js in order to generate
// accurate pageLoad start timings. This function is supplementary and starts the
// `until` listeners which will automatically stop the pageLoad.

interface StartViewPageLoadParams {
	contentId: string;
	isLite?: boolean;
	customConfig?: CustomViewPageLoadConfig;
}

export const StartViewPageLoad = ({ contentId, isLite, customConfig }: StartViewPageLoadParams) => {
	const { userId } = useSessionData();
	const pageLoadMetricRef = useRef<ReturnType<typeof createPageLoadMetric>>();

	// Create a page-load metric that will be finished based on the timings of each
	// of page-segment-load metrics we expect to see fired
	useEffect(() => {
		const _isInitialLoad = isInitial();
		const expectedPageSegments =
			customConfig?.expectedPageLoadSegments ??
			getExpectedPageLoadSegments({
				isAnonymous: Boolean(!userId),
				isInitialLoad: _isInitialLoad,
				isLite,
			});

		if (debuggerEnabled) {
			cacheExpectedViewPageSegments(expectedPageSegments);
		}

		// Marks a custom start time to track user clicks for temporary data gathering
		// Context: https://hello.atlassian.net/wiki/spaces/CCPERF/pages/1951426622
		ExperienceActionMeasures.markMeasureStart(VIEW_EDIT_BUTTON_CLICKED_ACTION);

		const untilExperienceMetricNames =
			customConfig?.fmpUntilStopTimeMetrics ??
			fmpAsUntilStopTimeMetrics({
				isLite,
			});

		// Create a list of untilPageSegments
		// This wraps the segments into an UntilExperience, so that @atlassian/browser-metrics can handle useFmpAsUntilStopTime gracefully
		// Note - this only affect initial load since transitions are not SSR'd, hence page-segments do not have fmp marks
		// More information why useFmpAsUntilStopTime can be found here
		// https://hello.atlassian.net/wiki/spaces/~484600559/pages/1515596554/Toolings+Update+Browser-Metrics+FMP+TTI
		const untilPageSegments = expectedPageSegments.map((segment) => ({
			experience: segment,
			useFmpAsUntilStopTime: untilExperienceMetricNames.includes(segment.key),
		}));

		pageLoadMetricRef.current = createPageLoadMetric({
			...baseConfig,
			key: customConfig?.pageLoadKey ?? VIEW_PAGE_LOAD_KEY,
			timings: [...baseConfig.timings, ...getWaterfallTimings(expectedPageSegments)],
			include: expectedPageSegments,
			until: untilPageSegments,
		});

		if (!_isInitialLoad) {
			// Make sure all segments are re-started and not currently marked as finished from a previous navigation
			expectedPageSegments.forEach((m) => m.startFromPageLoad());
		}

		pageLoadMetricRef.current.startPageLoad();
		pageLoadMetricRef.current.onStop(
			markBrowserMetricEnd.bind(null, pageLoadMetricRef.current),
			NOOP,
		);

		pageLoadMetricRef.current.onStop(resumeLowPriorityEvents, resumeLowPriorityEvents);
		pageLoadMetricRef.current.onStop(resetViewTransitionSource, resetViewTransitionSource);
		pageLoadMetricRef.current.onStop(
			(data) => markLegacyFY23TTI(pageLoadMetricRef.current, data),
			NOOP,
		);

		// Cleanup on unmount or when dependencies have changed
		return () => {
			pageLoadMetricRef.current?.cancel();
		};
	}, [contentId, customConfig, userId, isLite]);

	return null;
};

export const VIEW_PAGE_CONTAINER_METRIC = createPageSegmentLoadMetric({
	key: VIEW_PAGE_CONTAINER_SEGMENT_KEY,
	ssr: Boolean(window.__SSR_RENDERED__) ? { doneAsFmp: true } : undefined,
});
