import { useContext } from 'react';

import { RoutesContext, getSingleParam, getSingleParamInt } from '@confluence/route-manager';

type ViewPageParams = {
	versionOverride: null | number;
	embeddedContentRender: null | string;
	queryHash?: string;
};

export function useViewPageParams(): ViewPageParams {
	const { getQueryParams, getHash } = useContext(RoutesContext);
	const queryParams = getQueryParams();

	// Setting versionOverride and embeddedContentRender to null
	// if they don't have valid value
	const versionOverride: number | null = getSingleParamInt(queryParams, 'versionOverride', null);

	const embeddedContentRender = getSingleParam(queryParams, 'embeddedContentRender') || null;

	// Hash is being used in content body
	const queryHash = getHash();
	return {
		versionOverride,
		embeddedContentRender,
		queryHash,
	};
}
