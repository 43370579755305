import gql from 'graphql-tag';

export const ContentViewedEventQuery = gql`
	query ContentViewedEventQuery($contentId: ID) {
		content(id: $contentId, trigger: "viewed") {
			nodes {
				id
			}
		}
	}
`;
