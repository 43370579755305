import type { PageLoadMetric } from '@atlassian/browser-metrics';
import type { BaseMetricStopArguments } from '@atlassian/browser-metrics/types';

import { CONTENT_RENDERER_METRIC } from './perf.config';

export const markLegacyFY23TTI = (
	pageLoadMetric?: PageLoadMetric,
	data?: BaseMetricStopArguments | null,
) => {
	const contentRendererLegacyMark = CONTENT_RENDERER_METRIC.getData().marks['fy23-legacy-tti'] || 0;
	const pageLoadMark = data?.stopTime || 0;

	pageLoadMetric?.mark('fy23-legacy-tti', Math.max(pageLoadMark, contentRendererLegacyMark));
};
