import type { FC, RefObject } from 'react';
import React from 'react';
// We have deprecated unstated. Please use react-sweet-state instead
// eslint-disable-next-line no-restricted-imports
import { Subscribe } from 'unstated';

import { BannerStateContainer } from '@confluence/banners';

import { useHashLinkScrollHandler } from './useHashLinkScrollHandler';

export type ScrollToHashLinkHandlerProps = {
	/**
	 * The container of anchor elements. Provide this property to limit the scrolling behavior to a certain element.
	 *
	 * Use React ref for this:
	 *
	 *  ```js
	 *  const Content = () => {
	 *    const containerRef = useRef<HTMLDivElement>(null);
	 *
	 *    return (
	 *      <div ref={containerRef}>
	 *        <ScrollToHashLinkHandler anchorContainer={containerRef} />
	 *      </div>
	 *    )
	 *  }
	 * ```
	 */
	anchorContainer: RefObject<HTMLDivElement>;
};

const ScrollToHashLinkHandlerInner: FC<ScrollToHashLinkHandlerProps & { topOffset: number }> = ({
	anchorContainer,
	topOffset,
}) => {
	useHashLinkScrollHandler({
		anchorContainer,
		scrollCorrectionPixels: -topOffset,
	});

	return null;
};

/**
 * A helper component for making scrolling to in-document hash links easier.
 * This component is also aware of the presence of Confluence banners and/or top
 * navigation.
 *
 * ```js
 *  const Content = () => {
 *    const containerRef = useRef<HTMLDivElement>(null);
 *
 *    return (
 *      <div ref={containerRef}>
 *        <ScrollToHashLinkHandler
 *          anchorContainer={containerRef}
 *        />
 *        <h1 id="top">This is document top</h1>
 *        <br /><br /><br /><br /><br />
 *        <a href="#top">Link to top</a>
 *      </div>
 *    )
 *  }
 * ```
 */
export const ScrollToHashLinkHandler: FC<ScrollToHashLinkHandlerProps> = ({ anchorContainer }) => {
	return (
		<Subscribe to={[BannerStateContainer]}>
			{(bannerState: BannerStateContainer) => {
				return (
					<ScrollToHashLinkHandlerInner
						anchorContainer={anchorContainer}
						topOffset={bannerState.getTotalHeight()}
					/>
				);
			}}
		</Subscribe>
	);
};
ScrollToHashLinkHandler.displayName = 'ScrollToHashLinkHandler';
