import type { VFC } from 'react';
import React from 'react';

import { RequireLegacyWRM } from '@confluence/wrm';

const WRM_MODULES = [
	'wr!com.atlassian.confluence.plugins.confluence-jira-content:confluence-jira-content-loader',
];

export const InlineActionsLoader: VFC = () => (
	<RequireLegacyWRM wrm={WRM_MODULES}>{() => null}</RequireLegacyWRM>
);
