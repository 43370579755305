import type { PageSegmentLoadMetric } from '@atlassian/browser-metrics/types';

import { WATERFALL_INITIAL_MEASURES, WaterfallMeasures } from '@confluence/action-measures';

import { PAGE_SEGMENT_MARK_PREFIX_END, PAGE_SEGMENT_MARK_PREFIX_START } from './metric-keys';
import { browserMetricsState, isInitial } from './utils';

// We preload a bunch of queries on mousedown. This is actually before transition happens.
// At the moment when queries were sent we don't know the next route yet.
// So we need to look back a bit see if we can find any queries that were sent before.
const LOOK_BEHIND_MS = 500;

export function getWaterfallTimings(expectedPageSegments: PageSegmentLoadMetric[]) {
	// Create list of timings for every segment that indicate actual start and end time for it.
	// This is useful for displaying page loading sequence as a waterfall, while tracking every
	// segment from page load start. Waterfall timings are prefixed with `wf/`.
	const markers = expectedPageSegments.map((segment) => ({
		key: `wf/react/${segment.key}`,
		endMark: `${PAGE_SEGMENT_MARK_PREFIX_END}:${segment.key}`,
		startMark: `${PAGE_SEGMENT_MARK_PREFIX_START}:${segment.key}`,
	}));

	let measures;
	if (isInitial()) {
		measures = Object.values(WATERFALL_INITIAL_MEASURES);
	} else {
		measures = WaterfallMeasures.getMeasureKeys(
			browserMetricsState.pageLoadStartTimestamp - LOOK_BEHIND_MS,
		).filter((key) => key.startsWith('wf/client'));
		WaterfallMeasures.clearMeasures();
	}

	measures.forEach((key) => {
		markers.push({
			key,
			startMark: `${key}.start`,
			endMark: `${key}.end`,
		});
	});

	return markers;
}
